import * as React from "react"
import Layout from "../components/layout"
import IndexPage from "../components/pageComponents/index"
import messages from "../i18n/en"

const IndexPageEn = ({ location }) => (
  <Layout location={location} messages={messages}>
    <IndexPage langKey="en" />
  </Layout>
)

export default IndexPageEn
